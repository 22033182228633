.preview {
  composes: flex p-2 from global;
  overflow-x: auto;

  & > :last-child {
    & .attachment {
      margin-right: 0;
    }
  }
}

.attachment {
  composes: relative mr-2 from global;
  height: auto;
  overflow: visible;
  box-shadow: 0 2px 3px 0 rgba(156, 156, 156, 0.45);
}

.detach {
  composes: absolute flex items-center justify-center from global;
  top: -10px;
  right: -10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: #c83d43;
  border-radius: 100%;
  border: 4px solid #ffffff;
  width: 24px;
  height: 24px;
  z-index: 1;
  cursor: pointer;
}

.detach-icon {
  stroke: #ffffff;
}
