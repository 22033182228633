.container {
  composes: flex flex-auto items-center justify-between from global;
  padding: 0 16px;
  min-height: 48px;
  border-radius: 2px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  transition: 300ms linear;

  &:hover {
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid rgba(26, 26, 26, 0.6);
  }
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.text {
  &:hover {
    cursor: text;
  }
}

.container-active {
  /* using !important because the hover styles on .price override this added class */
  border-color: #0a76d9 !important;
  background-color: #ffffff;
}
