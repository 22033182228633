@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.wrapper {
  composes: flex flex-col from global;
  width: 100%;
  @media mBreakpointSm {
    max-width: 500px;
  }
}

.mobile-full-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #fff;
  @media mBreakpointSm {
    position: initial;
  }
}

.claimed-info {
  composes: flex flex-auto from global;
  min-height: 56px;
}

.unclaimed-info {
  composes: flex from global;
  min-height: 56px;
  flex: 0 1 auto;
}

.time {
  composes: flex items-center justify-center from global;
  flex: 0 0 56px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  animation: countdownColor 90s steps(1) both;

  @media mBreakpointSm {
    border-radius: 4px 0 0 0;
  }
}

.text-container {
  composes: flex items-center relative py-1 pl-2 pr-3 from global;
  flex: 1;
}

.progressBar {
  composes: absolute from global;
  top: 0;
  bottom: 0;
  left: 0;
  animation: progressBarColor 90s steps(1) both, progressBarSize 90s linear both;
}

.link {
  composes: flex flex-auto items-center from global;
  z-index: 2;
  font-size: 18px;
  color: #233034;
}

.accepted-block {
  composes: flex items-center justify-center from global;
  flex: 0 0 56px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  background-color: #087bcc;
}

.title {
  composes: flex flex-auto items-center from global;
  font-size: 18px;
  color: #233034;
  z-index: 2;
}

.actions {
  composes: flex from global;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.action {
  composes: flex items-center justify-center flex-auto from global;
  padding: 10px 0;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border: none;
  background-color: #fff;
}

.accept {
  composes: action;
  color: #087bcc;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.deny {
  composes: action;
  color: #c83d43;
}

@keyframes countdownColor {
  0% {
    background-color: #00aa45;
  }
  33.33% {
    background-color: #f06c24;
  }
  66.66% {
    background-color: #e63131;
  }

  100% {
    background-color: #e63131;
  }
}

@keyframes progressBarColor {
  0% {
    background-color: #c8e9d5;
  }
  33.33% {
    background-color: #fbe1d2;
  }
  66.66% {
    background-color: #fad6d6;
  }
  100% {
    background-color: #fad6d6;
  }
}

@keyframes progressBarSize {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
