@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.nav-link {
  composes: flex justify-between items-center from global;
  color: #b1b8ba;
  text-decoration: none;
  cursor: pointer;
  margin: 0 12px 0 0;
  padding: 4px 0 4px 1rem;
  border-radius: 0 2px 2px 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  @media mBreakpointSm {
    font-size: 14px;
    margin-right: 4px;
  }
}

.orange {
  color: var(--c-white);
  background-color: var(--c-primary);

  &:hover {
    background-color: var(--c-primary-light);
  }
}

.display {
  composes: flex items-center from global;
}

.left {
  max-width: 100%;
}

.display-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.nav-link-active,
.nav-link-active:hover {
  background-color: rgba(255, 255, 255, 0.9);
  color: #10151b;
}

.count {
  composes: font-bold flex items-center justify-center from global;
  padding: 2px 3px;
  margin-right: 2px;
  border-radius: 2px;
  min-width: 20px;
  font-size: 14px;

  @media mBreakpointSm {
    font-size: 12px;
    min-width: 16px;
  }
}

.badge {
  color: var(--c-white);
}

.primary {
  composes: badge;
  background-color: #e32900;
}

.secondary {
  composes: badge;
  background-color: var(--c-secondary);
}

.icon {
  composes: mr-1 from global;
}

.sub-text {
  line-height: 24px;
  letter-spacing: 0.25px;
}
