.container {
  composes: flex flex-col from global;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;

  & button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.text-field {
  flex: 0 0 auto;

  & input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }
}

.content-container {
  composes: flex flex-col from global;
  flex: 1 1 auto;
  overflow: auto;
}
