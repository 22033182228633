@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.wrapper {
  composes: flex flex-col from global;
  width: 100%;

  @media mBreakpointSm {
    max-height: min(600px, 100vh - 80px);
    width: 424px;
  }
}

.mobile-full-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  @media mBreakpointSm {
    position: initial;
  }
}

.quick-reply {
  @media mBreakpointSm {
    max-height: 300px;
  }
}

.dropdown {
  composes: flex flex-col flex-auto text-black from global;
  background-color: #fff;
  border-top: solid 1px rgba(0, 0, 0, 0.15);
}

.info {
  composes: flex from global;
  min-height: 50px;
}

.time {
  composes: flex items-center justify-center from global;
  flex: 0 0 56px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  animation: countdownColor 90s steps(1) both;

  @media mBreakpointSm {
    border-radius: 4px 0 0 0;
  }
}

.text-container {
  composes: flex items-center relative py-1 pl-2 pr-3 from global;
  flex: 1;
}

.progressBar {
  composes: absolute from global;
  top: 0;
  bottom: 0;
  left: 0;
  animation: progressBarColor 90s steps(1) both, progressBarSize 90s linear both;
}

.link {
  composes: flex flex-auto items-center from global;
  z-index: 2;
  font-size: 18px;
  color: #233034;
}

.sub-title {
  font-size: 14px;
  color: #3d4e56;
}

.reply {
  composes: flex items-center justify-center flex-auto from global;
  padding: 10px 0;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #087bcc;
  border: none;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

@keyframes countdownColor {
  0% {
    background-color: #00aa45;
  }
  33.33% {
    background-color: #f06c24;
  }
  66.66% {
    background-color: #e63131;
  }

  100% {
    background-color: #e63131;
  }
}

@keyframes progressBarColor {
  0% {
    background-color: #c8e9d5;
  }
  33.33% {
    background-color: #fbe1d2;
  }
  66.66% {
    background-color: #fad6d6;
  }
  100% {
    background-color: #fad6d6;
  }
}

@keyframes progressBarSize {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
