.container {
  composes: p-2 from global;
}

.button {
  composes: cursor-pointer flex items-center justify-center m-1 from global;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3);
}

.actions {
  composes: my-3.5 flex justify-center self-end from global;
  width: 100%;
}

.text {
  composes: text-center from global;
  width: 100%;
}

.hidden {
  composes: hidden from global;
}

.link {
  composes: cursor-pointer underline from global;
}
