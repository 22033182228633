.container {
  composes: flex justify-center items-center font-bold relative from global;
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 18px;
  border: none;
  cursor: pointer;

  &:hover {
    &:before {
      position: absolute;
      border: 2px solid rgba(255, 255, 255, 0.64);
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: -5px;
      border-radius: 50%;
      content: '';
    }
  }
}

.active {
  &:before {
    position: absolute;
    border: 2px solid var(--c-white) !important;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    border-radius: 50%;
    content: '';
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.badging {
  composes: absolute text-center flex justify-center items-center text-white from global;
  height: 21px;
  width: 21px;
  font-size: 9px;
  letter-spacing: 0.25px;
  background-color: var(--c-primary);
  top: -8px;
  right: -8px;
  border-radius: 50%;
  border: 2px solid #10151b;
}
