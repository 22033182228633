.popover {
  composes: p-2 from global;
  cursor: pointer;
}

.no-tags-text {
  font-size: 14px;
  font-weight: 400;
  color: #b1b8ba;
  text-decoration: none;
  margin: 0 12px 0 0;
  padding: 4px 0 4px 1rem;
  border-radius: 0 2px 2px 0;
}
