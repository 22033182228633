.button-container {
  composes: flex justify-between from global;
}

.button {
  composes: mr-0.5 from global;
  width: 100%;
}

.learn-more {
  composes: flex items-center justify-center ml-0.5 font-bold from global;
  width: 100%;
  color: var(--c-secondary);
}

.learn-more:hover {
  cursor: pointer;
  text-decoration: underline;
}
