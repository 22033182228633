.wrapper {
  width: 100%;
}

.container {
  composes: px-1 pb-1 from global;
  padding-top: 20px;
}

.header {
  padding-left: 14px;
  color: var(--c-black-75);
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.variable-container {
  padding: 14px 12px;

  &:hover {
    cursor: pointer;
    background-color: var(--c-black-5);

    & .variable-description {
      color: var(--c-secondary);
    }
  }
}

.variable-description {
  composes: text-black from global;
  line-height: 20px;
}

.variable {
  margin-top: 4px;
  font-size: 14px;
  line-height: 18px;
  color: var(--c-black-75);
}
