.container {
  composes: pt-2 from global;
}

.header {
  composes: font-medium text-black pl-2 from global;
  font-size: 24px;
  line-height: 30px;
}

.row {
  composes: flex items-center p-2 bb-normal from global;
}

.number-block {
  composes: bg-black text-white font-bold flex justify-center items-center radius-2 from global;
  font-size: 18px;
  line-height: 23px;
  width: 48px;
  height: 48px;
}

.info {
  composes: ml-2 from global;
}

.info-main {
  composes: text-black from global;
  font-size: 18px;
  line-height: 23px;
}

.info-sub {
  composes: body-small text-black-50 from global;
}
