@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.wrapper {
  composes: flex flex-col from global;
  width: 100%;
}

.top-bar {
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, #f94d1f -2.78%, #d34168 100%);
}

.content {
  composes: flex flex-col items-center from global;
  margin: 25px 0;

  @media mBreakpointMd {
    margin: 40px 0;
  }
}

.thumb {
  width: 64px;
  height: 64px;
  flex-shrink: 0;
}

.headline {
  margin-top: 25px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  color: var(--c-black-100);

  @media mBreakpointMd {
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 0.25px;
  }
}

.body {
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 0 25px;
  color: var(--c-black-75);
  margin-bottom: 24px;

  @media mBreakpointMd {
    font-size: 16px;
    max-width: 600px;
    line-height: 24px;
    padding: 0;
    margin-bottom: 32px;
  }
}

.browse-header {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
}
