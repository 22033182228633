.video {
  composes: flex items-center from global;
}

.container {
  composes: mt-2 from global;
  border-top: 4px solid var(--c-black-5);
}

.image-container {
  composes: relative mt-1 from global;
  cursor: pointer;
  padding-bottom: calc(50% - 4px);
  background-color: var(--c-black-5);

  & > :first-child {
    position: absolute;
    object-fit: contain;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    inset: 16px;
  }
}
