/**
  * Ensure text remains visible during webfont load
  * Some browsers hide text until the font loads, causing a flash
  * The easiest way to avoid showing invisible text while custom fonts load is to temporarily show a system font.
  * By including font-display: swap in your @font-face style, you can avoid FOIT in most modern browsers:
  * https://web.dev/font-display/
  */

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-book.woff2') format('woff2'),
    url('./fonts/circular-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-bold-italic.woff2') format('woff2'),
    url('./fonts/circular-bold-italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-medium.woff2') format('woff2'),
    url('./fonts/circular-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-black-italic.woff2') format('woff2'),
    url('./fonts/circular-black-italic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-book-italic.woff2') format('woff2'),
    url('./fonts/circular-book-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-medium-italic.woff2') format('woff2'),
    url('./fonts/circular-medium-italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-bold.woff2') format('woff2'),
    url('./fonts/circular-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Circular';
  src: url('./fonts/circular-black.woff2') format('woff2'),
    url('./fonts/circular-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
