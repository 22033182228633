@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: bg-light-gray from global;
  width: 100%;
  height: 100%;
}

.inner-container {
  composes: flex flex-col justify-between items-center mx-auto from global;
  max-width: 1600px;
  height: 100%;
  padding: 16px 24px 24px;
}

.header {
  composes: flex justify-between items-center from global;
  width: 100%;
}

.experts {
  composes: text-black from global;
  line-height: 20px;
  letter-spacing: 2.5px;

  @media mBreakpointMd {
    font-size: 20px;
    line-height: 25px;
  }
}

.footer {
  composes: text-center from global;
  line-height: 20px;
  color: var(--c-black-75);
}

.link {
  composes: footer;
  text-decoration: underline;
}
