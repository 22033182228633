@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.check-container {
  composes: flex items-center from global;
  width: 25px;
  flex-shrink: 0;
}

.check {
  @media mBreakpointMd {
    fill: #233034;
  }
}
