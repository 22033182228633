@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';
.container {
  composes: font-bold flex radius-1 bg-white items-center justify-center from global;
  min-width: 66px;
  width: 66px;
  line-height: 24px;
  padding: 4px;
  color: var(--c-error);
  font-size: 14px;

  @media mBreakpointSm {
    width: 82px;
    padding: 4px 12px;
  }
}
