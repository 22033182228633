.impersonation-link {
  composes: flex justify-center py-2 from global;
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  cursor: pointer;
  background: rgb(213, 33, 41);
  margin-top: auto;

  @media mBreakpointMd {
    font-size: 14px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
}
