@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.icon {
  composes: fixed from global;
  bottom: 20px;
  left: 20px;
  z-index: 10000001;
  cursor: pointer;
}

.hide {
  display: none;
}

.count {
  composes: absolute flex items-center justify-center from global;
  width: 16px;
  height: 16px;
  background: #e32900;
  border-radius: 50%;
  border: 2px solid #fff;
  top: -4px;
  right: -4px;
  color: #fff;
  font-size: 10px;

  @media mBreakpointSm {
    border-color: #10151b;
  }
}

.text-container {
  composes: absolute flex justify-center from global;
  max-width: 100%;
}

.text {
  composes: whitespace-nowrap font-bold text-white radius-1 from global;
  padding: 1px 2px;
  background-color: var(--c-primary);
  font-size: 10px;
}
