.thumbnail {
  composes: flex items-center relative from global;
  border-radius: 4px;
  border: 2px solid transparent;
  cursor: pointer;
  overflow: hidden;

  & > div {
    position: relative;
    width: 100%;
    min-width: 50px;
    padding-bottom: 100%;

    & > img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &:hover {
    border-color: var(--c-black-25);
  }
}

.thumbnail-selected {
  border-color: var(--c-black-100) !important;
}

.overlay {
  composes: text-white bold flex items-center justify-center absolute from global;
  inset: 0;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.48);
  border-radius: 4px;
}
