.wrapper {
  composes: flex flex-col justify-center items-center from global;
  width: 100%;
}

.result {
  composes: flex items-center justify-center flex-col from global;
  margin-bottom: 24px;
  width: 88px;
  height: 88px;
  border-radius: 50%;
}

.pass {
  background-color: var(--c-success-pale);
  color: var(--c-success);
}

.fail {
  background-color: var(--c-error-pale);
  color: var(--c-error);
}

.score {
  composes: font-bold from global;
  font-size: 20px;
  line-height: 25px;
}

.outcome {
  composes: font-medium from global;
  font-size: 12px;
  line-height: 15px;
}

.title {
  composes: mb-1 text-black font-bold from global;
  font-size: 32px;
  line-height: 40px;
}

.sub-title {
  composes: text-black-75 from global;
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 20px;
}

.footer {
  composes: flex items-center from global;

  & > *:first-child {
    margin-right: 8px;
  }
}
