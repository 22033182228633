@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.outer-container {
  composes: flex flex-col from global;
  width: 100%;
  overflow-y: auto;

  @media mBreakpointSm {
    flex-direction: row;
    position: relative;
    /* This is the max height allowed by the modal */
    height: min(90vh, calc(90vh - 56px));
  }
}

.container {
  composes: pt-2 px-2 flex flex-col from global;
  width: 100%;
  flex: 1;

  @media mBreakpointSm {
    padding: 32px 0 0 32px;
  }
}

.header {
  composes: text-black body-large flex justify-between pr-3 from global;
  font-weight: bold;

  @media mBreakpointSm {
    flex: 0 0 auto;
    font-size: 24px;
    line-height: 28px;
  }
}

.header-left {
  composes: flex items-end from global;
}

.loader {
  composes: ml-2 from global;
}

.save-pill {
  composes: ml-2 px-1 body-small text-med-gray flex items-center justify-center from global;

  & > * {
    margin-left: 8px;
    font-size: 5px;
  }
}

.bubble-loader {
  & > * {
    width: 4px;
    height: 4px;
  }
}

.popover-container {
  composes: flex items-center from global;

  & > :not(:first-child) {
    margin-left: 8px;
  }
}

.split-container {
  composes: flex flex-col from global;
  padding-top: 32px;
  height: 100%;
  max-height: calc(100% - 32px);
  min-height: 600px;

  @media mBreakpointSm {
    min-height: 0;
    flex-direction: row;
    flex: 1 0 0;
  }
}

.left-container {
  composes: flex flex-col justify-between mb-2 from global;
  flex: 2 0 0;

  & > :not(:first-child) {
    margin-top: 16px;
  }

  @media mBreakpointSm {
    padding-bottom: 32px;
    padding-right: 32px;
    border-right: 1px solid var(--c-black-10);
  }
}

.suggested-message-tags {
  composes: flex from global;
  overflow-x: auto;
  flex: 0 0 auto;

  & > * {
    flex: 0 0 auto;
  }

  & > :not(:last-child) {
    margin-right: 8px;
  }

  @media mBreakpointSm {
    flex-direction: column;
    overflow-y: auto;
    min-height: 0px;
    flex: 1 0 0px;

    & > :not(:first-child) {
      margin-top: 12px;
    }
  }
}

.quick-replies-container {
  flex: 7 0 0;
  min-height: 0px;
  max-height: 100%;
  overflow-y: auto;

  & > :not(:first-child) {
    border-top: 4px solid var(--c-black-5);
  }

  @media mBreakpointSm {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.loading {
  opacity: 0.4;
  transition: opacity 0.25s;
  pointer-events: none;
}
