@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.side {
  composes: flex items-center justify-center from global;
  border-radius: 4px 0 0 4px;
  flex: 0 0 56px;
  background-color: #e63131;
}

.text-container {
  composes: flex flex-col relative py-1 pl-2 pr-3 from global;
  flex: 1;
}

.title {
  composes: flex flex-auto items-center mb-1 from global;
  font-size: 18px;
  color: #233034;
  z-index: 2;
}

.link-container {
  margin-bottom: 3px;
}

.sub-title {
  composes: mb-1 from global;
  font-size: 14px;
  color: #3d4e56;
}

.error {
  font-size: 14px;
  color: #233034;
}
