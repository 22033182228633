@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.nav-footer {
  margin-top: auto;
  padding: 16px 0;
  background-color: #1d282b;
  border-top: 1px solid #000;
}

.footer-menu {
  composes: list-reset m-0 from global;
  width: 100%;

  & > li:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.pdp-icon,
.notification-icon {
  width: 16px;
  height: 16px;
}

.reactivate-link {
  composes: flex justify-center py-2 from global;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  margin-top: auto;

  @media mBreakpointMd {
    font-size: 14px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
}
