@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: flex items-center justify-center from global;
  font-size: 16px;
  line-height: 20px;
  color: #1a1a1a;
  border-top: 4px solid #0de163;
  min-height: 64px;
  width: 100%;
  flex: 1;
  padding: 15px;

  @media mBreakpointSm {
    max-width: 350px;
  }
}

.calling {
  composes: flex items-center from global;
}

.message {
  composes: flex align-items justify-center mb-2 from global;
}

.content {
  flex: 1;
}

.input-wrapper {
  padding: 0 8px !important;
}

.input {
  color: #1a1a1a;
  outline: none;
  border: none;
  background-color: inherit;
  width: 100%;
}

.name {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  text-underline-offset: 2px;
  color: #1a1a1a;
  margin-left: 4px;
}

.button {
  composes: flex items-center relative justify-center from global;
  right: -8px;
  flex: 0 0 48px;
  height: 48px;
  background: #0a76d9;
  border-radius: 2px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 24px;
  z-index: 2;
  cursor: pointer;

  &:disabled {
    background-color: #b7b7b7;
  }
}

.notes {
  composes: mt-1 from global;
  & > .note:not(:last-of-type) {
    margin-bottom: 4px;
  }
}

.note {
  font-size: 14px;
  line-height: 18px;
  padding: 7px 8px;
  color: rgba(26, 26, 26, 0.87);
  background: #f7f7f7;
  border-radius: 2px;
}

.row {
  composes: flex from global;
}

/**
 * ==============================================
 * Dot Flashing
 * https://codepen.io/nzbin/pen/GGrXbp
 * ==============================================
 */

.snippet:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5px;
  content: attr(data-title);
  font-size: 0.75rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  color: white;
  background-color: rgb(255, 25, 100);
  border-radius: 0.25rem 0 0.25rem 0;
}

.stage {
  display: flex;
  justify-content: center;
  margin: 0 -5%;
  width: 50px;
}

.snippet {
  position: relative;
  background: #fff;
}

.dotFlashing {
  margin-left: 9px;
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dotFlashing::before,
.dotFlashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dotFlashing::before {
  left: -9px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dotFlashing::after {
  left: 9px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #000;
  }
  50%,
  100% {
    background-color: #eee;
  }
}
