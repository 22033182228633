@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: flex flex-col items-center elevation-2 bg-white from global;
  padding: 0 0 32px;
  border-radius: 10px;
  width: 100%;

  @media mBreakpointMd {
    padding: 0 32px 56px;
  }
}

.avatar {
  margin-top: -73px;
  border: 4px solid var(--c-white);
}

.name {
  composes: text-black heading-two from global;
  margin-top: 32px;
  line-height: 40px;

  @media mBreakpointMd {
    margin-top: 24px;
    font-size: 40px;
    line-height: 54px;
  }
}

.deactivation-date {
  composes: mt-1 from global;
  color: var(--c-black-75);
  line-height: 20px;

  @media mBreakpointMd {
    margin-top: 4px;
  }
}

.body {
  composes: mt-2 body-medium px-2 from global;

  & > div {
    margin-top: 16px;
  }

  @media mBreakpointMd {
    font-size: 18px;
    line-height: 24px;
    padding: 0;
  }
}

.requests {
  composes: flex flex-col items-center p-2 from global;
  margin: 24px 0px 0px;
  width: 100%;
  background-color: var(--c-black-5);

  @media mBreakpointMd {
    padding: 16px 0px;
    border-radius: 10px;
  }
}

.request {
  font-size: 14px;
  line-height: 24px;

  color: var(--c-black-75);

  @media mBreakpointMd {
    font-size: 16px;
  }
}

.button {
  margin-top: 40px;

  @media mBreakpointMd {
    margin-top: 32px;
  }
}
