.container {
  padding: 24px 0;
  border-bottom: 1px solid #35464b;
}

.header {
  composes: px-2 mb-1 from global;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #bebebe;
}

.links {
  & > a:not(:last-child) {
    margin-bottom: 8px;
  }
}
