.container {
  composes: flex flex-center from global;
  width: 359px;
  min-height: 144px;
}

.orange {
  composes: flex flex-center from global;
  align-items: center;
  justify-content: center;
  flex: 0 0 56px;
  background-color: #f55700;
}

.content {
  composes: flex flex-col from global;
  padding: 24px 16px;
  color: #233034;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
}

.button-yes {
  color: #ffffff;
  background-color: #1a1a1a;
  border-width: 2px;
  margin-top: 16px;
  margin-right: 8px;
  font-weight: bold;
  padding-left: 22px;
  padding-right: 22px;
  &:hover {
    background-color: #1a1a1a;
  }
}

.button-no {
  color: #1a1a1a;
  border-width: 2px;
  margin-top: 16px;
  margin-right: 8px;
  font-weight: bold;
  padding-left: 22px;
  padding-right: 22px;
}
