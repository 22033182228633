@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.dropdown {
  composes: flex flex-col flex-auto text-black from global;
  background-color: #fff;
  border-top: solid 1px rgba(0, 0, 0, 0.15);
}

.quick-reply {
  @media mBreakpointSm {
    max-height: 300px;
  }
}
