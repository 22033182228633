@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.modal {
  & > div {
    padding: 0;
    margin: 0;
  }
}

.content {
  composes: flex flex-col from global;
  padding: 24px;
}

.title {
  composes: font-bold mb-1 from global;
  font-size: 18px;
  color: var(--c-black-100);
}

.description {
  composes: mb-2.5 text-black-50 from global;
  font-size: 14px;
  line-height: 18px;
}

.button-container {
  width: 100%;
  composes: flex justify-center from global;

  @media mBreakpointSm {
    justify-content: flex-start;
  }
}

.button {
  composes: mr-0.5 from global;
  width: 100%;

  @media mBreakpointSm {
    width: 142px;
  }
}
