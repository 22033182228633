.page {
  padding: 24px 32px 40px;
}

.header {
  font-size: 20px;
  line-height: 25px;
  color: var(--c-black-100);
  font-weight: 500;
}
