.image-container-no-results {
  composes: relative mt-1 from global;
  padding-bottom: calc(50% - 4px);
  background-color: var(--c-black-5);

  & > :first-child {
    position: absolute;
    object-fit: contain;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    inset: 16px;
  }
}

.no-result {
  composes: flex flex-col items-center justify-center text-dark-gray text-center from global;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.23px;

  & > svg {
    margin-bottom: 18px;
  }
}
