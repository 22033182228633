@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: text-white flex items-center justify-center px-1 from global;
  font-size: 14px;
  background: var(--c-error);
  width: 100%;
  min-height: 62px;
  line-height: 18px;

  @media mBreakpointSm {
    min-height: 40px;
    line-height: 24px;
    padding: 0 16px;
  }
}
.content {
  margin-left: 10px;
  line-height: 18px;

  @media mBreakpointSm {
    composes: ml-3 from global;
  }
}

.button {
  composes: p-1 pl-2 from global;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  text-decoration: underline;
  flex-shrink: 0;
  margin: 0;

  @media mBreakpointSm {
    border-radius: 6px;
    padding: 0 12px;
    line-height: 24px;
    border: 1px solid var(--c-white);
    text-decoration: none;
    margin-left: 1rem;
  }
}
