@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.layout {
  composes: flex flex-auto from global;
  z-index: 0;
}

.menu {
  composes: fixed flex flex-col from global;
  flex: 0 0 190px;
  background-color: #10151b;
  box-shadow: 2px 0 15px 0px rgba(0, 0, 0, 0.45);
  z-index: 10;
  top: 0;
  height: 100%;
  width: 80%;
  overflow-y: auto;
}

.nav {
  composes: menu;
  left: -100%;
  transition: left 0.5s;
  padding-bottom: 72px;

  @media mBreakpointMd {
    z-index: initial;
    position: static;
    height: auto;
    width: auto;
    box-shadow: none;
  }
}

.nav-opened {
  left: 0;
}

.user-menu {
  composes: menu;
  right: -100%;
  transition: right 0.5s;
}

.user-menu-opened {
  right: 0;
}

.overlay {
  composes: fixed top-0 left-0 right-0 bottom-0 from global;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  /* Use opacity and pointer-events instead of display: none, so we can include a transition */
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;

  @media mBreakpointMd {
    display: none;
  }
}

.overlay-visible {
  opacity: 1;
  pointer-events: auto;
}

.close-button {
  composes: absolute flex items-center justify-around from global;
  top: 12px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.5);
  border: solid 1px #ffffff;
  cursor: pointer;
  display: none;
}

.navigation-close-button {
  display: flex;
  right: 12px;
}

.user-menu-close-button {
  display: flex;
  left: 12px;
}

.close-icon {
  fill: #ffffff;
}

.not-clickable {
  cursor: initial;
  &:hover {
    background-color: #ffffff;
  }
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  margin-left: 8px;
}

.folder-count {
  composes: pr-1 from global;
  font-size: 14px;
  font-weight: bold;

  @media mBreakpointMd {
    font-size: 12px;
  }
}

.main {
  composes: flex flex-auto from global;
}
