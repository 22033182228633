.toast-container {
  border-radius: 6px !important;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 10px 15px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
}

.success-container {
  composes: toast-container;
  border: solid 1px var(--c-black-10) !important;
}

.error-container {
  composes: toast-container;
  border: solid 1px var(--c-error) !important;
}

.toast-body {
  composes: p-1 from global;
  min-height: 80px;
  font-family: 'Circular', system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: calc(100% - 16px);
  overflow-wrap: break-word;
}

.success-body {
  composes: toast-body;
  color: #1d9b50;
}

.error-body {
  composes: toast-body;
  color: var(--c-error);
}
