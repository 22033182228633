@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.text-container {
  composes: flex flex-col relative py-1 pl-2 pr-3 from global;
  flex: 1;
}

.title {
  composes: flex flex-auto items-center mb-1 from global;
  font-size: 18px;
  color: #233034;
  z-index: 2;
}

.sub-title {
  composes: mb-1 from global;
  font-size: 14px;
  color: #3d4e56;
}
