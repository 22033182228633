.container {
  border-bottom: 1px solid #35464b;
}

.top-line {
  composes: flex justify-start items-center from global;
  padding: 24px 16px;
  cursor: pointer;
}

.header {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #bebebe;
}

.arrow-icon {
  composes: mr-1 flex justify-center items-center from global;
  transition: transform 200ms;
}

.options-content {
  composes: ml-auto from global;
}

.rotate {
  transform: rotate(-180deg);
}

.children {
  margin-top: -16px;
  padding-bottom: 24px;
}
