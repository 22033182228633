@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.text {
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #b1b8ba;

  @media mBreakpointSm {
    font-size: 14px;
  }
}
