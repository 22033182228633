.container {
  & section {
    padding-bottom: 24px;
    margin: 24px 0;
    border-bottom: 1px solid var(--c-black-10);
  }

  & h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
  }

  & h4 {
    margin-top: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--c-black-75);
  }

  & p,
  & li {
    margin-top: 16px;
    font-size: 15px;
    line-height: 22px;
    color: var(--c-black-87);
  }

  & ol {
    padding-left: 24px;
  }

  & a {
    color: var(--c-secondary);

    &:hover {
      text-decoration: underline;
    }
  }
}

.toast-body {
  composes: flex flex-col from global;

  & > div {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}

.toast-content {
  composes: flex items-center justify-between from global;
  height: 100%;
}

.toast-icon {
  composes: mr-1 from global;
}

.toast-header {
  color: var(--c-black-100);
}

.toast-body-text {
  color: var(--c-black-50);
  font-weight: 400;
}
