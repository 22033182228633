@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.toast {
  composes: flex from global;
  min-height: 64px;
}

.side {
  composes: flex items-center justify-center from global;
  border-radius: 4px 0 0 4px;
  flex: 0 0 56px;
  height: 100%;
  background-color: #009f45;
}

.text-container {
  composes: flex flex-auto ml-2 flex-col justify-center from global;
}

.title {
  composes: font-bold from global;
  margin-bottom: 2px;
  font-size: 16px;
  color: #233034;
}

.sub-title {
  font-size: 14px;
  color: #3d4e56;
}
