.loader {
  width: 20px;
}

.container {
  padding: 24px;
}

.header {
  composes: font-bold text-black from global;
  font-size: 18px;
  line-height: 23px;
}

.paragraphs {
  composes: mt-1 from global;
  color: var(--c-black-75);

  & > :not(:first-child) {
    margin-top: 16px;
  }
}

.variables-container {
  composes: p-2 from global;
  margin-top: 24px;
  background-color: var(--c-black-5);
}

.variables-header {
  color: var(--c-black-75);
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.variables {
  margin-top: 12px;

  & > :not(:first-child) {
    margin-top: 24px;
  }
}

.variable {
  composes: text-black from global;
  line-height: 20px;
  font-weight: 500;
}

.variable-description {
  margin-top: 4px;
  font-size: 14px;
  line-height: 18px;
  color: var(--c-black-75);
}
