.search-row {
  padding: 0 24px 8px;
  border-bottom: 4px solid var(--c-black-5);
}

.result {
  composes: mt-1 from global;
  cursor: pointer;
}

.image-container {
  composes: relative from global;
  padding-bottom: 100%;
  background-color: var(--c-black-5);

  & > :first-child {
    position: absolute;
    object-fit: contain;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    inset: 16px;
  }
}

.image-title {
  composes: mt-1 from global;
  font-size: 14px;
  line-height: 18px;
  color: var(--c-black-75);
}

.magnifier-container {
  composes: absolute from global;
  bottom: 4px;
  left: 4px;
}

.no-results {
  composes: flex justify-center from global;
  flex-grow: 1;
}
