@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.wrapper {
  composes: flex flex-col from global;
  width: 100%;

  @media mBreakpointSm {
    max-height: min(600px, 100vh - 80px);
    width: 424px;
  }
}

.mobile-full-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #fff;
  @media mBreakpointSm {
    position: initial;
  }
}

.body {
  composes: flex from global;
  flex: 0 0 56px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
}

.icon-block {
  composes: flex items-center justify-center from global;
  background-color: #3074b5;
  height: 100%;
  flex: 0 0 56px;
}

.message {
  composes: flex-auto flex flex-col justify-center from global;
  margin-left: 16px;
  height: 100%;
}

.new-message {
  font-size: 18px;
  color: #1a1a1a;
  margin-bottom: 4px;
}

.name {
  font-size: 14px;
  color: #434343;
}

.actions {
  flex: 0 0 80px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}

.action {
  composes: flex items-center justify-center flex-auto from global;
  padding: 10px 0;
  width: 100%;
  cursor: pointer;
  font-size: 14x;
  background-color: inherit;
  outline: none;
  border: none;
}

.reply {
  composes: action;
  color: #1a1a1a;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.close {
  composes: action;
  color: #1a1a1a;
}
