.container {
  composes: flex items-center from global;
  padding: 12px 20px;
  background-color: #f2f9fe;
  color: var(--c-black-75);
  font-size: 14px;
  line-height: 18px;
  border-radius: 4px;

  & > svg {
    margin-right: 14px;
  }
}

.active {
  composes: container;
  color: var(--c-black-100);
}

.popover-container {
  padding: 24px;
}
