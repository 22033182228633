@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: mb-2 from global;

  @media mBreakpointMd {
    margin-bottom: 12px;
  }
}

.completed-amount {
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #bebebe;

  @media mBreakpointMd {
    justify-content: start;
    color: #e4e4e4;
  }
}

.pending-amount {
  composes: font-bold from global;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.25px;
}

.green {
  position: relative;
  color: #34d637;

  &::after {
    content: '';
    position: relative;
    bottom: 7px;
    left: 2px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #34d637;

    @media mBreakpointMd {
      border: none;
    }
  }
}

.plus {
  display: none;

  @media mBreakpointMd {
    display: inline-block;
  }
}
