@import './fonts.css';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-display: swap;
  font-family: 'Circular', system-ui, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

:global(#root),
:global(#deal) {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  min-width: 0;
  z-index: 1;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'Circular', system-ui, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
