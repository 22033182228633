@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.menu {
  background-color: #10151b;
  width: 100%;

  @media mBreakpointMd {
    padding: 0;
    background: #fff;
    width: 250px;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.27);
    background-color: #ffffff;
    border: solid 1px #d9dee1;
    z-index: 10;
  }
}

.signout {
  & li {
    color: #d40009;
  }
  border-bottom: none;
}

.container {
  composes: flex justify-between from global;
  padding: 24px 16px;
  background-color: #10151b;
  font-size: 14px;
  color: #ffffff;
  border-bottom: 1px solid #35464b;
}

.name {
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.25px;
}

.status {
  composes: flex items-center from global;
  margin-top: 4px;
  font-size: 15px;
  flex-shrink: 0;
}
