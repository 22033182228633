@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: flex flex-col py-2 from global;
  flex: 1 0;

  @media mBreakpointSm {
    padding: 24px 0;
  }

  & > input,
  & textarea {
    border: 1px solid transparent;
    padding: 8px 12px;
    border-radius: 4px;

    &:hover {
      background-color: var(--c-black-5);
      border-color: var(--c-black-25);
    }

    &:focus {
      border-color: var(--c-secondary);
      background-color: var(--c-white);
    }
  }

  & textarea {
    width: 100%;
    margin-top: 8px;
    resize: vertical;
  }
}

.loading {
  opacity: 0.4;
  transition: opacity 0.25s;
  pointer-events: none;
}

.name {
  composes: body-medium from global;

  @media mBreakpointSm {
    font-size: 18px;
    line-height: 24px;
  }
}

.text-container {
  composes: grow from global;
}

.text {
  composes: body-medium from global;
  color: var(--c-black-75);

  @media mBreakpointSm {
    font-size: 18px;
    line-height: 24px;
  }
}

.bottom-line {
  composes: flex justify-between items-center mt-2 from global;
}

.tags {
  composes: flex flex-wrap items-center from global;
  & > :not(:last-child) {
    margin-right: 8px;
  }

  & > * {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.actions {
  composes: flex flex-col from global;

  & > :not(:first-child) {
    margin-top: 8px;
  }

  @media mBreakpointSm {
    flex-direction: row;
    align-items: center;

    & > :not(:first-child) {
      margin-top: 0;
      margin-left: 8px;
    }
  }
}

.icon {
  composes: mr-1 from global;
  fill: none !important;
}

.star-icon {
  composes: icon;

  & > :nth-child(2) {
    display: none;
  }
}

.star-button {
  width: 100%;
}

.invert-icon {
  & > :first-child {
    display: none;
  }

  & > :nth-child(2) {
    display: block;
  }
}

.text-area-container {
  width: 100%;
}

.errorText {
  composes: mt-1 from global;
  font-size: 14px;
  color: var(--c-error);
}
