@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: flex items-center from global;
  margin-top: 4px;
  font-size: 15px;
  flex-shrink: 0;

  @media mBreakpointMd {
    font-size: 14px;
  }
}

.status {
  padding: 4px 8px;
  border: 1px solid;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
}

.status-off {
  composes: status;
  border-color: #ff625b;
  background-color: transparent;
  color: #ff625b;
}

.status-on {
  composes: status;
  border-color: #008a03;
  background-color: #008a03;
  color: #ffffff;
}

.status-blocked {
  composes: status;
  border-color: #e69d9a;
  background-color: #cd3c36;
  color: #ffffff;
}
