.warning {
  composes: radius-1 from global;
  background-color: #e3e6ba;
  padding: 20px;
  margin-bottom: 20px;
}

.confirmation {
  padding-top: 20px;
}
