@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: flex relative from global;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  @media mBreakpointSm {
    min-width: 350px;
  }
}

.close-button {
  composes: absolute flex items-center p-0 justify-center from global;
  z-index: 1;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background: transparent;
  cursor: pointer;
  stroke: #222;
  border-radius: 50%;
  border: none;
}
