.list-container {
  composes: p-2 flex flex-col from global;

  & > :not(:first-child) {
    margin-top: 12px;
  }
}

.popover-content {
  max-height: min(40vh, 400px);
}
