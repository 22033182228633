.content {
  flex: 1;
}

.modal-content {
  width: 100%;
  overflow-x: hidden;
}

.button {
  width: 100%;
}

.speed {
  composes: flex items-center justify-between from global;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #626262;
}

/* SLIDER */

.slider {
  position: relative;
  width: 100%;
  height: 60px;
}

.rail {
  position: absolute;
  width: 100%;
  height: 10px;
  margin-top: 35px;
  border-radius: 5px;
  background-color: #b8c1ce;
}

.handle {
  position: absolute;
  margin-left: -15px;
  margin-top: 25px;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: #2c4870;
  width: 28px;
  height: 28px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1),
    0 3px 1px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.track {
  position: absolute;
  height: 10px;
  z-index: 1;
  margin-top: 35px;
  background-color: #f55700;
  border-radius: 5px;
  cursor: pointer;
}

.tick {
  position: absolute;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #626262;
}

.active {
  color: #1a1a1a;
  font-size: 18px;
  font-weight: bold;
}
