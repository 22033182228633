@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: fixed bg-white from global;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 24px;
  z-index: 999999;
  box-shadow: 0px 9px 20px -2px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--c-black-10);

  @media mBreakpointSm {
    border-radius: 8px;
    width: 500px;
    bottom: 8px;
    left: 8px;
  }
}

.close-line {
  composes: flex justify-end from global;
  width: 100%;

  & > svg {
    cursor: pointer;
  }
}

.header {
  composes: font-bold text-black from global;
  font-size: 24px;
  line-height: 30px;
}

.items {
  composes: mt-2 from global;
  padding-bottom: 24px;

  & > :not(:first-child) {
    margin-top: 20px;
  }
}

.item-header {
  composes: text-black from global;
  font-weight: 500;
  line-height: 24px;
}

.item-text {
  margin-top: 4px;
  font-size: 14px;
  line-height: 18px;
  color: var(--c-black-75);

  & strong {
    color: var(--c-black-100);
  }
}

.video-container {
  composes: relative from global;
  max-width: 100%;
  margin-top: 40px;

  & > video {
    max-width: 100%;
  }
}

.overlay {
  composes: items-center justify-center bg-black absolute from global;
  opacity: 0.2;
  inset: 0;
  display: none;

  @media mBreakpointSm {
    display: flex;
  }
}

.play-icon {
  cursor: pointer;
}
