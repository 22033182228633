.toast {
  composes: flex flex-auto items-center from global;
  width: 100%;
  border-radius: 4px;
  padding: 0 !important;
  overflow: visible !important;
  outline: none;

  @media mBreakpointSm {
    width: 424px;
    box-shadow: 0 2px 25px 1px rgba(0, 0, 0, 0.35);
  }
}

.body-class {
  composes: flex flex-auto from global;
  margin: 0;
}
