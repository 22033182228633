.panel-titles {
  composes: flex from global;
  margin: 16px 24px 8px;
}

.panel-title {
  composes: flex items-center justify-center from global;
  max-width: calc(100% / 2);
  cursor: pointer;

  margin-right: 24px;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 6px;
  color: #767676;
  border-bottom: 4px solid transparent;
}

.active-panel-title {
  color: #222222;
  border-bottom-color: #0a76d9;
}
