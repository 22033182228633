.container {
  composes: flex p-2 flex-col from global;
  background-color: #10151b;
  flex-shrink: 0;
  font-size: 14px;
  color: #ffffff;
  border-bottom: 1px solid #35464b;
}

.name {
  composes: flex items-center from global;
  font-size: 14px;
}

.arrow-icon {
  margin-left: 8px;
}

.needs-action-badging {
  composes: flex flex-none justify-center items-center from global;
  height: 16px;
  width: 16px;
  background-color: #c83d43;
  border-radius: 2px;
  margin-right: 5px;
  color: #ffffff;
  font-size: 12px;
}

.lower-container {
  composes: pt-1 from global;
}

.department {
  composes: font-bold from global;
  margin-top: 4px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.25px;
  opacity: 0.64;
}
