.table {
  width: 100%;
  border: 1px solid var(--color-neutrals-300);
  table-layout: fixed;

  th,
  td {
    padding: 8px;
    border: 1px solid var(--color-neutrals-300);
    max-width: 50%;

    & > :first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
