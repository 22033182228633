@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.menu-row {
  composes: flex justify-between items-center font-bold relative from global;
  color: #fff;
  flex-grow: 1;
  line-height: 24px;

  @media mBreakpointMd {
    padding: 8px 16px;
    background: #fff;
    font-size: 14px;
    color: #233034;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.menu-row-title {
  composes: flex justify-start from global;
}

.menu-row-subtitle {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #bebebe;
}

.title-line {
  composes: flex items-center from global;

  & > svg {
    margin-left: 8px;
  }
}

.badging {
  composes: flex flex-none justify-center items-center from global;
  height: 16px;
  width: 16px;
  background-color: #c83d43;
  border-radius: 2px;
  margin-right: 5px;
  color: #ffffff;
  font-size: 12px;
}

.locked-overlay {
  composes: absolute bg-black from global;
  inset: 0;
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
