.status {
  composes: flex items-center pl-2 from global;
  margin-top: 4px;
  font-size: 14px;
  flex-shrink: 0;

  @media mBreakpointMd {
    font-size: 14px;
  }
}

.online-indicator {
  height: 7px;
  width: 7px;
  border-radius: 4px;
  margin-right: 4px;

  @media mBreakpointMd {
    height: 6px;
    width: 6px;
    border-radius: 3px;
  }
}

.on-shift {
  composes: online-indicator;
  background-color: #56c71e;
}

.off-shift {
  composes: online-indicator;
  background-color: #ff4040;
}

.status-text {
  color: #ffffff;
}
