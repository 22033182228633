@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: flex items-center justify-center text-white px-1 from global;
  font-size: 14px;
  background-color: var(--c-error);
  min-height: 62px;
  line-height: 18px;

  @media mBreakpointSm {
    min-height: 40px;
    line-height: 24px;
    padding: 0 16px;
  }

  & > svg {
    margin-right: 16px;
  }
}

.button {
  color: var(--c-white);
  text-decoration: underline;
  font-size: 14px;

  &:hover {
    color: var(--c-white);
  }
}
