.container {
  composes: flex flex-wrap from global;
  padding: 8px 24px 16px;

  & > * {
    flex: 0 1 calc(50% - 4px);
  }

  & > :nth-child(2n) {
    margin-left: 4px;
  }

  & > :nth-child(2n + 1) {
    margin-right: 4px;
  }
}

.loader-container {
  composes: flex items-center justify-center from global;
  flex-grow: 1;
}
