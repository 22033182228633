@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.container {
  width: 100%;

  @media mBreakpointSm {
    max-width: 240px;
    max-height: 275px;
  }
}

.content {
  composes: flex flex-col from global;
  padding: 16px 20px;
}

.title {
  composes: font-bold mb-1 from global;
  font-size: 18px;
  color: var(--c-black-100);
}

.description {
  composes: mb-2 text-black-50 from global;
  font-size: 14px;
  line-height: 18px;
}

.background-image {
  height: 100%;
  text-align: right;
}

.close {
  composes: mr-1.5 mt-1 from global;
  cursor: pointer;
}
