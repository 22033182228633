@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.container {
  padding: 24px 16px;
  border-bottom: 1px solid #35464b;

  @media mBreakpointMd {
    border-color: rgba(0, 0, 0, 0.15);
    padding: 0;
  }
}

.menu-section-title {
  composes: mb-1 from global;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #bebebe;
  vertical-align: middle;

  @media mBreakpointMd {
    margin-bottom: 0;
    padding: 8px 16px;
    color: #1a1a1a;
  }
}

.menu-section {
  composes: list-reset m-0 from global;

  & > :not(:first-child) {
    margin-top: 8px;
  }

  @media mBreakpointMd {
    & > :not(:first-child) {
      margin: 0;
    }
  }
}
