.container {
  padding: 24px;
}

.header {
  composes: font-bold text-black from global;
  font-size: 18px;
  line-height: 23px;
}

.paragraphs {
  composes: mt-1 from global;
  color: var(--c-black-75);

  & > :not(:first-child) {
    margin-top: 16px;
  }
}
