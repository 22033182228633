.time-container {
  composes: flex justify-between from global;
}

.left {
  composes: flex flex-col justify-end from global;
  flex: 0 0 48%;
}

.right {
  flex: 0 0 48%;
}

.field {
  composes: mt-2 from global;
}

.field-label {
  font-size: 12px;
  color: #5a6b76;
  font-weight: bold;
}

.notification {
  margin-top: 15px;
}
