.mobile-call {
  composes: flex items-center justify-between from global;
  padding: 6px;
  background-color: #19f673;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  position: relative;
}

.name-container {
  composes: flex items-center from global;
}

.name {
  composes: font-bold from global;
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 2px;
  margin-left: 4px;
}
