@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.toast {
  composes: flex flex-auto from global;
  min-height: 64px;
}

.side {
  composes: flex items-center justify-center flex-none from global;
  height: 100%;
  border-radius: 4px 0 0 4px;
  background-color: var(--color-learn-blue-400);
  color: var(--color-neutrals-white);

  & > svg {
    width: 56px;
    height: 56px;
  }
}

.text-container {
  composes: flex flex-auto ml-2 flex-col justify-center from global;
  gap: 2px;
}
