.message-container {
  composes: relative from global;
  cursor: pointer;
}

.message-container-no-message {
  cursor: not-allowed;
}

.suggested-message-title {
  composes: flex items-center font-bold from global;
  line-height: 20px;

  & svg {
    margin-left: 8px;
  }
}

.suggested-message-text {
  margin-top: 4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--c-black-75);
  word-break: break-word;
}

.gallery {
  composes: flex items-center mt-0.5 from global;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  & > div {
    margin-top: 8px;
    border-radius: 4px;
    overflow: hidden;
    max-height: 50px;
    max-width: 50px;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
