.container {
  & :global(.react-tenor--search-bar) {
    padding: 0 24px 8px;
    box-shadow: none;
    border-bottom: 4px solid var(--c-black-5);
  }

  & :global(.react-tenor--spinner) {
    display: none;
  }

  & :global(.react-tenor--search) {
    background-color: var(--c-black-5);
    border: none;
    border-radius: 4px;
    box-shadow: none;
    height: 48px;
  }

  & :global(.react-tenor--autocomplete) {
    display: none;
  }

  & :global(.react-tenor--suggestions) {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 24px 12px;

    & > button {
      margin-top: 12px;
      border-width: 1px;
      margin-left: 0;
    }

    & > :not(:last-child) {
      margin-right: 8px;
    }
  }

  & :global(.react-tenor--results) {
    padding: 8px 24px 0;

    & > button {
      flex: 0 1 calc(50% - 4px);
      padding: 16px;
      background-color: var(--c-black-5);
      margin-bottom: 8px;
      border-radius: 8px;
      height: 150px;
    }

    & > :nth-child(2n) {
      margin-left: 4px;
    }

    & > :nth-child(2n + 1) {
      margin-right: 4px;
    }

    & :global(.react-tenor--page-left),
    & :global(.react-tenor--page-right) {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & :global(.react-tenor--page-left) {
      left: 24px;
    }

    & :global(.react-tenor--page-right) {
      right: 24px;
    }
  }
}
