@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.button {
  cursor: pointer;
  & path {
    fill: #8295a6;
  }
}

.icon {
  composes: relative flex from global;
}

.flat {
  & .button path {
    fill: unset;
  }
}

.wrapper {
  height: 100%;
  width: 100%;
}
