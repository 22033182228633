@value mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: flex items-center pl-2 from global;
  min-height: 62px;
  border-bottom: 1px solid #35464b;
  background-color: #192227;

  & > :not(:last-child) {
    margin-right: 16px;
  }

  @media mBreakpointMd {
    padding-top: 12px;
    padding-left: 0;
    flex-direction: column;
    align-self: stretch;
    flex: 0 0 51px;
    border-bottom: none;
    border-right: 1px solid #35464b;

    & > :not(:last-child) {
      margin-bottom: 16px;
      margin-right: 0;
    }
  }

  & span:nth-of-type(3n) > button,
  & button:nth-of-type(3n) {
    background-color: #f78517;
  }

  & span:nth-of-type(3n + 1) > button,
  & button:nth-of-type(3n + 1) {
    background-color: #e0aeff;
  }

  & span:nth-of-type(3n + 2) > button,
  & button:nth-of-type(3n + 2) {
    background-color: #84eca7;
  }
}

.modal {
  composes: flex justify-center from global;
  overflow: visible;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}
