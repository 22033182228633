.search-row {
  padding: 0 24px 8px;
  border-bottom: 4px solid var(--c-black-5);
}

.result {
  composes: mt-1 from global;
  cursor: pointer;
  flex: 0 1 calc(50% - 4px);
  max-width: calc(50% - 4px);
}

.image-container {
  composes: relative from global;
  padding-bottom: 100%;
  background-color: var(--c-black-5);

  & > :first-child {
    position: absolute;
    object-fit: contain;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    inset: 16px;
  }
}

.image-title {
  composes: mt-1 from global;
  font-size: 14px;
  line-height: 18px;
  color: var(--c-black-75);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.display-link {
  margin-top: 4px;
  font-size: 12px;
  line-height: 15px;
  color: var(--c-black-75);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-container {
  composes: p-2 flex flex-col justify-center items-center from global;
  flex-grow: 1;
}

.magnifier-container {
  composes: absolute from global;
  bottom: 4px;
  left: 4px;
}
