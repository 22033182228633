@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

/* Icon component */

.icon-container {
  composes: flex items-center flex-col from global;
  font-size: 14px;
}

.browser-link {
  composes: font-bold mb-2 mt-1 from global;
  color: #087bcc;
}

.icon {
  composes: mb-1 from global;
  height: 72px;
}

/* Upgrade Modal */

.modal {
  composes: fixed flex flex-col items-center from global;
  background-color: #000000;
  overflow: auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 99999999;
  color: #ffffff;
}

.top-bar {
  composes: mb-2 from global;
  flex: 0 0 4px;
  width: 100%;
  background-color: #f55700;
}

.logo-bar {
  composes: flex justify-center from global;
  width: 100%;
  height: 30px;
  margin-bottom: 45px;

  @media mBreakpointSm {
    margin-bottom: 100px;
  }
}

.modal-body {
  composes: px-2 from global;
  width: 100%;
  margin-bottom: 40px;
  max-width: 850px;
}

.header {
  margin: 0 0 20px 0;
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  @media mBreakpointSm {
    font-size: 48px;
    text-align: center;
    margin: 60px 0 40px 0;
  }
}

.text-container {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.5;

  @media mBreakpointSm {
    font-size: 18px;
    text-align: center;
    line-height: 1.78;
  }
}

.icons {
  composes: flex from global;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  @media mBreakpointSm {
    justify-content: center;
  }
}
