@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.modal {
  composes: flex flex-col from global;

  @media mBreakpointSm {
    flex-direction: row;
  }
}

.fancy-showcase {
  composes: flex items-center justify-center p-2 from global;
  background-color: #233034;
  color: #fff;
}

.content {
  composes: flex flex-col items-center from global;
}

.modal-content {
  & .reminder:last-of-type {
    margin-bottom: 24px;

    @media mBreakpointSm {
      margin-bottom: 32px;
    }
  }
}

.value {
  composes: pb-2 mb-2 from global;
  border-bottom: 4px solid #62727a;
  line-height: 46px;
  height: 70px;
  font-size: 64px;
  letter-spacing: 3px;
}

.label {
  composes: mb-2 from global;
  text-align: center;
  font-size: 24px;
}

.message {
  font-size: 16px;
  color: #b1b8ba;
}

.reminder {
  composes: p-1 flex items-center from global;
  margin-bottom: 4px;
  color: #233034;
  font-size: 16px;
  background-color: #fafafa;
  border-radius: 2px;
  border-left: 4px solid black;

  @media mBreakpointSm {
    padding: 1rem;
  }
}

.icon {
  margin-right: 12px;
  width: 16px;

  @media mBreakpointSm {
    margin-right: 18px;
    width: 24px;
  }
}

.button {
  width: 100%;
}
