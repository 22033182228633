@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.popover-content {
  width: 380px;

  @media mBreakpointSm {
    min-height: 662px;
  }
}

.panel-display-container {
  padding-top: 24px;
  height: 85vh;

  @media mBreakpointSm {
    height: auto;
  }
}

.panel-copy-container {
  padding: 0 24px;
}

.panel-display-title {
  composes: panel-copy-container;
  composes: text-black from global;
  margin-bottom: 4px;
  padding: 0 24px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
}

.panel-titles {
  composes: flex justify-center mt-2 mx-2.5 mb-1 from global;

  & > :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & > :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid var(--c-black-10);
  }
}

.panel-title {
  composes: flex items-center justify-center from global;
  max-width: calc(100% / 4);
  margin: 0;
  padding: 6px 10px;
  cursor: pointer;
  border-top: 1px solid var(--c-black-10);
  border-bottom: 1px solid var(--c-black-10);
  border-left: 1px solid var(--c-black-10);
}

.active-panel-title {
  composes: text-white bg-black from global;
  border-color: var(--c-black-100);
}

.wrapper {
  height: 100%;
  width: 100%;
}
