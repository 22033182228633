@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.container {
  padding: 12px 16px 0;
}

.top-line {
  composes: flex justify-between items-center font-bold from global;
  font-size: 16px;
}

.lower-container {
  & > * {
    margin-top: 8px;
  }
}

.view-leads-button {
  margin-top: 8px;
  width: 100%;
}

.view-leads-button {
  margin-top: 8px;
  width: 100%;
}
