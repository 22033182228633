.switch {
  composes: my-1 from global;
}

.segmented-control {
  height: 30px;
  background-color: rgba(255, 255, 255, 0.12) !important;
  border-color: rgba(255, 255, 255, 0.55) !important;
}

.paused-segment-background {
  background-color: rgba(255, 255, 255, 0.12) !important;
  border-color: rgba(255, 255, 255, 0.55) !important;
}

.on-segment-background {
  background-color: #008a03 !important;
  border-color: #008a03 !important;
}

.segment-segment {
  color: #ffffff !important;
}
