@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.popover-content {
  composes: flex flex-col content-start from global;
  padding: 28px 20px 0px;
  height: 100%;

  @media mBreakpointSm {
    /* use same width as large size popover */
    max-width: 100%;
  }
}

.back-button {
  width: fit-content;
}

.top-line {
  composes: flex justify-between items-center from global;
  width: 100%;
}

.header {
  composes: body-large from global;
}

.manage {
  composes: body-small from global;
  color: var(--c-secondary);
  cursor: pointer;
}

.dropdown {
  composes: py-2 from global;

  & > :not(:first-child) {
    margin-top: 4px;
  }
}

.messages-container {
  composes: pt-1 relative from global;
  flex: 1 1 auto;

  @media mBreakpointSm {
    min-height: 0;
    overflow-y: auto;
  }

  & > :first-child {
    padding: 0px 0 16px;
  }

  & > :not(:first-child) {
    padding: 16px 0;
    border-top: 1px solid var(--c-black-10);
  }
}
