@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.side {
  composes: flex items-center justify-center from global;
  flex: 0 0 56px;
  border-radius: 2px;
  box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.2);
  background-color: #233034;
}

.text-container {
  composes: flex flex-col relative py-1 pl-2 pr-3 from global;
  flex: 1;
}

.title {
  composes: flex flex-auto items-center mb-1 from global;
  font-size: 18px;
  color: #233034;
  z-index: 2;
}

.sub-title {
  composes: mb-1 from global;
  font-size: 14px;
  color: #3d4e56;
}
