@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.container {
  padding: 12px 16px 0;
}

.top-line {
  composes: flex justify-between items-center from global;
}

.count {
  composes: flex justify-center items-center font-bold from global;
  width: 16px;
  height: 16px;
  color: #10151b;
  font-size: 12px;
  line-height: 24px;
  background-color: #ffffff;
  border-radius: 2px;
}

.lower-container {
  & > * {
    margin-top: 8px;
  }
}

.claim-button {
  margin-top: 8px;
  width: 100%;
}

.content {
  padding: 16px 24px;
  width: 100%;
  max-width: 250px;
  font-size: 16px;
}

.hover-card-title {
  composes: font-bold mb-1 from global;
}

.content-button {
  composes: mt-2 from global;
}

.status {
  composes: flex items-center from global;
  margin-top: 4px;
  flex-shrink: 0;
}
