.container {
  composes: pt-2 px-3 pb-3 flex flex-col items-center from global;
  text-align: center;

  & .close-button {
    margin-left: auto;
    opacity: 0.5;
  }

  & > h4,
  > span {
    margin-bottom: 16px;
  }
}
