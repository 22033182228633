@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.modal {
  width: 100% !important;
  max-height: 90%;

  @media mBreakpointSm {
    width: 90% !important;
    height: 90%;
    margin-bottom: 56px;
    overflow: visible;
  }
}

.modal-content {
  composes: relative from global;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.loading {
  composes: absolute flex items-center justify-center from global;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.arrow {
  composes: absolute from global;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.arrow-left {
  composes: arrow;
  left: 0px;

  @media mBreakpointSm {
    left: -40px;
  }
}

.arrow-right {
  composes: arrow;
  right: 0px;

  @media mBreakpointSm {
    right: -40px;
  }
}

.close {
  composes: absolute hidden sm-inline-block from global;
  cursor: pointer;
  top: 0;
  right: -40px;
}

.button {
  composes: absolute from global;
  left: 50%;
  bottom: -48px;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
}

.image-thumbnails-container {
  composes: absolute flex flex-wrap content-start py-2 from global;
  width: 150px;
  max-height: 100%;
  top: 0px;
  right: 0;
  overflow-y: auto;
  gap: 8px;
}
