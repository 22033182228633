.suggested-message-tag {
  composes: body-medium flex item-center from global;
  overflow: hidden;
  border-radius: 16px;
  width: fit-content;
}

.content {
  padding: 4px 12px;
  color: var(--c-black-75);
  background-color: var(--c-black-5);
}

.content-with-remove {
  padding-right: 3px;
}

.clickable {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    background-color: var(--c-black-10);
  }
}

.active-suggested-message-tag {
  background-color: var(--c-black-100);
  color: var(--c-white);

  &:hover {
    background-color: var(--c-black-100);
  }
}

.remove {
  composes: flex items-center from global;
  padding: 8px 12px 8px 8px;
  background-color: var(--c-black-5);
  cursor: pointer;

  &:hover {
    background-color: var(--c-black-10);
  }
}

.deprecated {
  background-color: var(--c-error-pale);
}
