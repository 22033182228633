@value mBreakpointSm from '~#src/app/assets/styles/breakpoints.css';

.expert {
  composes: flex items-center py-2 mb-2 from global;
  color: #222222;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.message {
  composes: ml-1 from global;
  line-height: 1.4;
}

.headline {
  composes: my-2 from global;
  font-size: 18px;
  font-weight: bold;
  color: #222222;
}

.content {
  composes: radius-1 b-dark-gray px-2 pb-2 from global;
}

.qualities-container {
  composes: flex flex-wrap from global;
}

.quality-header {
  composes: mb-1 from global;
  font-size: 14px;
  color: #222222;
}

.quality {
  composes: mr-1 mb-1 from global;
  border-radius: 16px;
  padding: 5px 10px;
  border: solid 1px #e1e1e1;
  background-color: #efefef;
  font-size: 14px;
  font-weight: 500;
  color: #222222;
}

.comment {
  font-size: 16px;
  line-height: 1.5;
  color: #434343;
}

.star-container {
  composes: flex from global;

  & > svg {
    margin-right: 3px;
  }
}

.consumer-details {
  font-size: 14px;
  color: #767676;
}
