.search-row {
  composes: flex from global;

  & input {
    box-shadow: none;
    border: none;
    background-color: var(--c-black-5);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  & button {
    padding: 12px;
    border-width: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.icon {
  margin: 0 !important;
  height: 24px !important;
  width: 24px !important;
  
  & circle {
    fill: none;
  }
}

.icon-disabled {
  & * {
    stroke: var(--c-black-25);
  }
}